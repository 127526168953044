import React, { useState, useEffect } from "react";
import { Send } from 'react-feather';
import { API } from '../../config'

import './chat.css'


function Chat(props) {
  let { socket, user } = props
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('')

  useEffect(() => {
    socket.on("chat", msg => {
      setMessages([...messages, msg]);
      const chatContainer = document.getElementById('chatCont')

      if ((chatContainer.scrollHeight - 500) - chatContainer.scrollTop < 300) {
        chatContainer.scrollTop = chatContainer.scrollHeight
      }
    });
    return () => { socket.off() }
  }, [messages]);


  let submitChat = (e) => {
    e.preventDefault();

    if (message !== "") {
      let palabrotas = /^put|^gonorr|^culo|^chocha|^pipi|^malparid|^hijueputa|^jueputa|^pirob|^maricon|^maric|^maricad|^imbecil|^gueba|^gueva|^gurrupleta|^guevon|^huevon|^weva|^care chimba|^chimba|^monda|^mondá|^mierd|^fuck/gi
      let splitedMsg = message.split(" ");
      let cleanMsg = ""
      let time = new Date()
      time = `${time.getHours() < 10 ? "0" + time.getHours() : time.getHours()}
              :${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}` //:${time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds()}

      splitedMsg.map(ele => {
        let cleanWord = ''
        cleanWord = ele.toLowerCase().replace(palabrotas, ('*'.repeat(ele.length)));
        cleanMsg += `${cleanWord.includes('*') ? cleanWord : ele} `
      })

      const data = {
        message: cleanMsg,
        user: user,
        timeMsg: time
      }

      socket.emit("chat", data)
      setMessage('')
    } else {
      let chatInput = document.getElementsByClassName('chatInput')[0]

      let bgColor = chatInput.style.backgroundColor

      chatInput.style.backgroundColor = "#F12A51"

      setTimeout(() => {
        chatInput.style.backgroundColor = bgColor;
      }, 100)
    }
  }


  let submitForo = (e) => {
    e.preventDefault();
    if (message.trim() === '' || !user) return
    const data = {
      message: message,
      userId: user.userID,//CAMBIAR POR USER.ID
      response: ''
    }
    console.log("message sent");
    console.log(data);
    var conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch(`${API}/messages/`, conf)
      .then(res => {
        if (res.status === 400) {
          console.log('error mensaje')
        } else {
          return res.json();
        }
      })
      .then(dt => {
        if (dt !== undefined) {
          alert('Pregunta Enviada', true)
          setMessage('')
        }
      })

    /* --------------------------------------------------- */
  }

  return (
    <div className="ChatContainer">
      <div className='chat' id="chatCont">
        <div className='chatBuffer'>
          {
            messages.map((msg, i) => {
              return (
                <div key={i} className={msg.user.userID === user.userID ? 'chatOwnCard' : 'chatCard'}>
                  <div>
                    <p className='chatUsername'>{msg.user.nickName}</p> {/*" | " + msg.time */}
                    <p className='chatMessage'>{msg.message}</p>
                  </div>
                  {
                  (msg.response && msg.response !== '')
                    ?
                      <div>
                        <hr size='2px' style={{ width: '100%' }} />
                        <p className='chatUsername'>respuesta</p>
                        <p className='chatMessage'>{msg.response}</p>
                      </div>
                    : ''
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      <form className='chatForm' onSubmit={props.type === "foro" ? submitForo : submitChat}>
        <span className="azul"></span>
        <span className="rojo"></span>
        <input onChange={(e) => setMessage(e.target.value)} type="text" className='chatInput' value={message} placeholder='Tu mensaje / Type your message' />
        <button type='submit' className='chatInputButton'><Send /></button>
      </form>
    </div>
  );
}

export default Chat;
