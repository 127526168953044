import { useEffect, useState } from 'react'
import Stream from '../../components/stream/stream'
import TriviaUser from '../../components/trivia/triviaUser';
import Chat from '../../components/chat/chat'
import Cookies from 'universal-cookie';
import { API, ws } from '../../config'
import io from "socket.io-client";

import './room.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Room = (props) => {
  const cookies = new Cookies();
  const [triviaStatus, setTriviaStatus] = useState(false)
  const [iframClient, setIframeClient] = useState('')

  let user = cookies.get('sessionUser')

  if (user === undefined) {
    window.location.href = "/";
  }

  useEffect(() => {
    document.title = "Teleperformance | Room"

    socket.emit('onload', 'onload'); //onConnect

    return () => { socket.off() }
  }, [])

  let logOut = (evt) => {
    cookies.remove('sessionUser')
    window.location.href = "/";
  }

  return (
    <div className="roomContainer">
      <a href="https://www.teleperformance.com/es-es/" target="_blank" rel="noopener noreferrer"><div className="imgLogo"></div></a>
      <button className="logOut" onClick={e => {logOut(e)}}>Salir / Log Out</button>

      <div className="sections">
        <div className="stream">
          <div className="onAir"></div>
          <div className="titleHide"></div>
          <div className="player">
            <Stream socket={socket} iframeSock={iframClient} />
          </div>
        </div>

        <div className="interactions">
          <div className="corner top-left"></div>
          <div className="corner top-right"></div>
          <div className="corner right"></div>
          <div className="corner left"></div>
          <div className="corner bottom-left"></div>
          <div className="corner bottom-right"></div>


          <div className="title"></div>

          <Chat socket={socket} user={user || {}} type="chat"/> {/* type: foro */}
        </div>

        <TriviaUser userID={user.userID || "Invitado"} state={{ triviaStatus: [triviaStatus, setTriviaStatus] }} />
      </div>
    </div>
  )
}

export default Room
