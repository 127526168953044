import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Admin from './pages/admin/admin';
import Login from './pages/login/login'
import Room from './pages/room/room'
import Cover from "./pages/cover"

let Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/room" component={Room}/>
        <Route exact path="/admin" component={Admin}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes