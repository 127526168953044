import { useEffect } from 'react'
import Form from './form/form'

import './login.css'

let Login = (props) => {
  let phone = "3023180804"
  let country = "+57"
  let urlHelp = `https://wa.me/${country}${phone}`

  useEffect(() => {

    document.title = "Teleperformance | Log In"

    return () => {
      // UnMount
    }
  }, [])

  return (
    <div className="loginContainer">
      <a href="https://www.teleperformance.com/es-es/" target="_blank" rel="noopener noreferrer"><div className="imgLogo"></div></a>
      <div className="UI"></div>
      <div className="forms">
        <Form sendText="Ingresar / Log in"/>
      </div>
      <a className="soporte" href={urlHelp} target="_blank" rel="noopener noreferrer"></a>
    </div>
  )
}

export default Login
