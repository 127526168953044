import { useState } from "react"

import './checkBox.css'

let CheckBox = (props) => {
  const { tyc: [tyc, setTyc] } = { tyc: useState(''), ...(props.state || {}) }
  const [modal, setModal] = useState(false)

  let closeAnyWay = (evt) => {
    if (evt.className === "ModalCont") {
      setModal(false)
    }
  }

  return (
    <div className="checkBoxContainer">
      <label className="customCheckBox" htmlFor={props.id} onClick={e => {setTyc(!tyc)}}>
        <div className="checkCont">
          <span className="azul"></span>
          <span className="rojo"></span>
          {
            tyc ? <span className="check ok">&#10004;</span> : <span className="check not">	&#10008;</span>
          }
        </div>
        <div className="textCont" onClick={e => {setModal(!modal)}}>
          {props.text}
          <br/>
          {props.englishText}
        </div>
      </label>

      {
        modal &&
        <div className="ModalCont" onClick={e => {closeAnyWay(e.target)}}>
          <div className="close" onClick={e => {setModal(false)}}>X</div>
          <div className="modalstyle">
            <div className="azul"></div>
            <div className="rojo"></div>
            <div className="modalInner">
              <div className="iconW"></div>
              <h2>Políticas de privacidad</h2>
              <p className="textModal">
                TELEPERFORMANCE COLOMBIA SAS (en adelante “TELEPERFORMANCE”), es el responsable del tratamiento de los datos personales que usted proporcione. En tal condición, se requiere su autorización para que, de manera libre, voluntaria y debidamente informada, nos permita almacenar, usar, circular, suprimir, procesar y en general, dar tratamiento a los datos que sean suministrados por usted y que sean incorporados en las bases de datos de TELEPERFORMANCE.
                <br/>
                <br/>
                Le informamos que esta información será utilizada y procesada para el registro y participación en el evento <strong>“1 ANNIVERSARY”.</strong>
                <br/>
                <br/>
                En caso de que requiera actualizar, rectificar o corregir sus datos personales, usted podrá, manifestarlo al siguiente correo electrónico: <a href="mailto:protecciondedatos@teleperformace.com">protecciondedatos@teleperformace.com</a>.
                <br/>
                <br/>
                Se informa que no se realizarán transferencias que requieran su consentimiento, salvo aquellas que sean necesarias para atender requerimientos de información de una autoridad competente, debidamente fundados y motivados. Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, usted, podrá acceder y consultar la Política de Tratamiento de Datos Personales de Teleperformance en el siguiente enlace: <a href={props.url} target="_blank" rel="noopener noreferrer">https://www.teleperformance.com/media/7861361/29032021-vf-politica-de-tratamiento-de-informacion-tpcolombia.pdf</a>.
                <br/>
                <br/>
                <strong>Nota:</strong> Al dar clic en Ingresar, usted autoriza de manera expresa, previa e informada, el tratamiento de sus datos personales de conformidad con las políticas de tratamiento de datos de Teleperformance y las finalidades aquí dispuestas.
              </p>

              {/* ------------------------------------------------ */}

              <div className="iconW"></div>
              <h2>Privacy Notice</h2>
              <p className="textModal">
                TELEPERFORMANCE COLOMBIA SAS (hereinafter "TELEPERFORMANCE"), is responsible for the processing of the personal data you provide. In such condition, your authorization is required so that, in a voluntary, free and duly informed manner, you allow us to store, use, circulate, suppress, process and in general, treat the data that is provided by you and that is incorporated in the databases of TELEPERFORMANCE.
                <br/>
                <br/>
                We inform you that this information will be used and processed for the registration and participation in the <strong>“1 ANNIVERSARY”</strong> event.
                <br/>
                <br/>
                In case you need to update, rectify or correct your personal data, you may express it to the following e-mail: <a href="mailto:protecciondedatos@teleperformace.com">protecciondedatos@teleperformace.com</a>.
                <br/>
                <br/>
                It is reported that there will be no transfers that require your consent, except those that are necessary to meet information requirements from competent authorities, duly grounded and motivated. For more information about the treatment and the rights that you can enforce, you can access and consult Teleperformance's Personal Data Treatment Policy at the following link: <a href={props.url} target="_blank" rel="noopener noreferrer">https://www.teleperformance.com/media/7861361/29032021-vf-politica-de-tratamiento-de-informacion-tpcolombia.pdf</a>.
                <br/>
                <br/>
                <strong>Note:</strong> By clicking on Sign In, you expressly, previously and informedly authorize the processing of your personal data in accordance with Teleperformance's data processing policies and the purposes set forth herein.
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CheckBox
