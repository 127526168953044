import { useState } from 'react'
import Cookies from 'universal-cookie';
import InputText from '../../../components/inputs/inputText'
import InputEmail from '../../../components/inputs/inputEmail'
import InputNumber from '../../../components/inputs/inputNumber'
import CheckBox from '../../../components/inputs/checkBox/checkBox'
import { API } from '../../../config'

import './form.css'

let Form = (props) => {
  const cookies = new Cookies();

  const [name, setName] = useState('')
  const [ccmsID, setccmsID] = useState('')
  const [email, setEmail] = useState('')
  const [checkBox, setCheckBox] = useState(false)
  const [loanding, setLoanding] = useState(false)
  const [error, setError] = useState(false)
  const [msgError, setMsgError] = useState('')

  let handleSubmit = (evt) => {
    evt.preventDefault()

    if (checkBox) {
      setLoanding(true)

      let body = {
        user: ccmsID,
        password: "",
        email: email,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      };

      fetch(`${API}/auth/login`, conf)
        .then(res => res.json())
        .then(res => {

          if (res.error === `Not found ${ccmsID}`) {
            setLoanding(false)
            setMsgError("ccms ID incorrecto | Wrong ccms ID")
            setError(true)
          } else if (res.jwt !== undefined) {

            let user = {
              role: res.data.role,
              userID: res.data.user._id,
              ccmsID: res.data.user.ccms,
              userName: res.data.user.name,
              userEmail: res.data.user.email,
              userJWT: res.jwt,
              nickName: name
            }

            if (res.data.user.ccms === process.env.REACT_APP_USER_CLIEN_ADMIN_ACCESS) {
              cookies.set('sessionAdmin', JSON.stringify(user), { path: '/', sameSite: true }); // secure: true,
              window.location.href = "/admin";
            } else {
              cookies.set('sessionUser', JSON.stringify(user), { path: '/', sameSite: true }); // secure: true,
              window.location.href = "/room";
            }
        } else {
          setMsgError("Ups! Algo salió mal, pronto se solucionará")
          setError(true)
        }
      })
    } else {
      setLoanding(false)
      setMsgError("Debes aceptar términos y condiciones | You must accept terms and conditions")
      setError(true)
    }
  }

  return (
    <div className="formContainer">
      {
        !loanding
        ? !error
          ? <form className="form" onSubmit={e => {handleSubmit(e)}}>
              <InputText className="userName" type="text" state={{ text: [name, setName] }} placeholder="Nombre / Name" />
              <InputNumber className="ccmsID" type="number" state={{ number: [ccmsID, setccmsID] }} placeholder="CCMS ID" />
              <InputEmail className="userEmail" type="email" theme="dark" wrongText="Correo incorrecto" placeholder="Correo / Mail" state={{ email: [email, setEmail] }}/>
              <CheckBox state={{ tyc: [checkBox, setCheckBox] }} englishText="I accept the terms and conditions. To learn more about Teleperformance's personal data processing policy click here." text="Acepto los términos y condiciones. Para consultar más sobre la política de tratamiento de datos personales de Teleperformance da clic aquí." url="https://www.teleperformance.com/media/4229746/data-privacy-policy_public-version_spa.pdf"/>
              <button className="btnSubmit" type="submit">{props.sendText}</button>
            </form>
          : <div className="errorMsg">
              <h2>{msgError}</h2>
              {
                msgError !== "Gracias" &&
                <button className="back" onClick={e => {setError(false)}}>Volver / Return</button>
              }
            </div>
        : <div className="loanding"></div>
      }
    </div>
  )
}

export default Form
