import React, { useState, useEffect } from "react";
import { API, ws } from '../../config'
import io from "socket.io-client";
import Cookies from 'universal-cookie';


const socket = io.connect(ws, { transports: ['websocket'] });

let Stream = (props) => {
  const cookies = new Cookies();
  const [iframeSocket, setIframeSocket] = useState(null)
  const [iframeDB, setIframeDB] = useState(null)
  const [zoomBtn, setZoomBtn] = useState(false)
  const [urlBtn, setUrlBtn] = useState('')

  let user = cookies.get('sessionUser')


  useEffect(() => {

    socket.on("zoomBtn", (data) => {
      setZoomBtn(data.status)
      setUrlBtn(data.url)
    });

    socket.on("streaming", data => {
      setIframeSocket(data.msg);
    });

    socket.on('user-list', (usersSocket) => {
      let countUID = 0;
      for (const userSocket in usersSocket) {
        if (usersSocket[userSocket].uID === user.userID) {
          countUID++;
        }
      }

      console.log(countUID);

      if (countUID > 1) {
        window.location.href = "/";
        countUID = 0
      }
    });

    socket.emit('new-user', {
      name: user.userName,
      uID: user.userID,
    });

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeDB(res.iframe)
      })

    return () => { socket.off() }
  }, []);

  return (
    <React.Fragment>
      {
        iframeSocket
        ? <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeSocket}}></div>
        : <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeDB}}></div>
      }
      {
        zoomBtn &&
        <a className="zoomBtn" href={urlBtn} target="_blank" rel="noopener noreferrer">
          <div className="imgZoom"></div>
          <p>Unirse a Zoom</p>
        </a>
      }
    </React.Fragment>
  )
}

export default Stream;
