import { useState, useEffect } from 'react'
import { API, ws } from '../../config'
import IframeMan from '../../components/stream/iframeMan'
import TriviaAdmin from '../../components/trivia/triviaAdmin2'
import SlideCheck from '../../components/inputs/slideCheck/slideCheck'
import Cookies from 'universal-cookie';

import io from "socket.io-client";

import './admin.css'
import InputText from '../../components/inputs/inputText'

const socket = io.connect(ws, { transports: ['websocket'] });

let Admin = ({ history }) => {
  const cookies = new Cookies();
  const [registers, setRegisters] = useState([])
  const [online, setOnline] = useState([])
  const [zoomBtn, setZoomBtn] = useState(false)
  const [consult, setConsult] = useState('')
  const [specific, setSpecific] = useState({})
  const [url, setUrl] = useState('')

  let user = cookies.get('sessionAdmin')

  if (user === undefined) {
    window.location.href = "/";
  }

  useEffect(() => {
    socket.emit("new-user", 'adminload');

    /* --------------------------------------------------- */

    // User Connection and Disconnection
    socket.on("user-connected", (user) => {
      console.log(`${user.name} ha entrado a la sala`);
    });

    socket.on("user-disconnected", (user) => {
      if (user !== null) {
        console.log(`Usuario ${user.name} se ha desconectado`);
      }
    });

    socket.on("user-list", (users) => {
      if (user !== undefined) {
        let listUsers= []
        for (const user in users) {
          listUsers.push(users[user])
        }
        setOnline(listUsers)
      }
    });

    return () => { }
  }, [])

  /* =================================================== */
  /* ===========USUARIOS REGISTRADOS =================== */

  let handleClickRegisters = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/register/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setRegisters(res)
      })
  }

  let handleConsult = (evt) => {
    evt.preventDefault()

    fetch(`${API}/register/${consult}`)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setSpecific(res)
      })
  }

  /* ===========USUARIOS REGISTRADOS =================== */
  /* =================================================== */

  useEffect(() => {
    socket.emit('zoomBtn', {
      url: url,
      status: zoomBtn,
    })
  }, [zoomBtn])


  const logOut = () => {
    cookies.remove('sessionAdmin')
    window.location.href = "/";
  }

  return (
    <div className="adminContainer">
      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className="card Zoom">
        <div className="title">
          <h2>Zoom</h2>
          <InputText type="text" placeholder="Ingrese la url" value={url} state={{ text: [url, setUrl] }}/>
          <SlideCheck state={{ slideCheck: [zoomBtn, setZoomBtn] }} />
        </div>
      </div>

      <div className="card onlineCont">
        <div className="title">
          <h2>Usuarios online</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              online.length === 1
                ? <h3>{`${online.length} Usuario online`}</h3>
                : <h3>{`${online.length} Usuarios online`}</h3>
            }
            <form onSubmit={e => { handleConsult(e) }}>
              <input type="text" value={consult} onChange={e => {setConsult(e.target.value)}}/>
              <button type="submit" className='adminButton'>Consultar</button>
            </form>
          </div>
          <div className="listRegisters">
            {
              online.map((ele, idx) => {
                return (
                  <div className="userInfo" key={idx}>
                    <p>{ele.name}</p>
                    <p>{ele.uID}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        {
          specific.name &&
          <div className="especificUser">
            <p>{specific.name}</p>
            <p>{specific.email}</p>
            <p>{specific.ccms}</p>
          </div>
        }
      </div>

      <div className="card gamesCont">
        <div className="title">
          <h2>Actividades</h2>
        </div>
        <TriviaAdmin socket={socket} />
      </div>

      <div className="card registers">
        <div className="title">
          <h2>Usuarios registrados</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              registers.length === 1
                ? <h3>{`${registers.length} Usuario registrado`}</h3>
                : <h3>{`${registers.length} Usuarios registrados`}</h3>
            }
            <button onClick={e => { handleClickRegisters(e) }} className='adminButton'>Cargar lista</button>
          </div>
          <div className="listRegisters">
            {
              registers.map((ele) => {
                return (
                  <div className="userInfo" key={ele._id}>
                    <p>{ele.name}</p>
                    <p>{ele.email}</p>
                    <p>{ele.mobile}</p>
                    <p>{ele.document}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card streaming">
        <IframeMan socket={socket}/>
      </div>

    </div>
  )
}

export default Admin
