import React, { useState, useEffect } from 'react'
import io from "socket.io-client";
import alert from '../alert'
import { API, ws } from '../../config'
import SlideCheck from '../inputs/slideCheck/slideCheck'
import Chart from 'chart.js'
import './triviaAdmin.css'

const socket = io.connect(ws, { transports: ['websocket'] });

const TriviaAdmin = () => {
  const [trivia, setTrivia] = useState([{ text: '', answerId: '0-0', choices: [{ text: '', _id: '0-0' }] }])
  const [createdTriviaId, setCreatedTriviaId] = useState('')
  const [createdTrivia, setCreatedTrivia] = useState(false)
  const [listOfWinners, setListOfWinners] = useState([])
  const [countTimes, setCountTimes] = useState(0)
  const [timeByQuestions, setTimeByQuestions] = useState('')
  const [gameSelect, setGameSelevt] = useState(false)
  let time = false
  let winnersObj = []

  /* ================ DRAW CHART =========================== */
  var labels = [];
  var votes = [];
  let chart
  if (document.getElementById('myChart') && !createdTrivia) {

    var ctx = document.getElementById('myChart').getContext('2d');
    chart = new Chart(ctx, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
        labels: labels,
        datasets: [{
          label: 'Votos',
          backgroundColor: '#00677F',
          borderColor: '#00677F',
          data: votes,
        }]
      },
      // Configuration options go here
      options: {
        title: {
          display: true,
          text: 'Encuesta',
          padding: 10,
          lineHeight: 1,
          fontSize: 16
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#EEE',
            },
            ticks: {
              beginAtZero: true,
            }
          }],
          xAxes: [{
            gridLines: {
              color: '#EEE',
            },
          }]
        },
        legend: {
          display: false,
        },
        events: []
      }
    });
  }

  function updatingChart(lab, vot, tit) {
    let vots = []
    vot.forEach(e => vots.push(e[0]))
    if (chart) {
      chart.options.title.text = tit;
      chart.data.labels = lab;
      chart.data.datasets[0].data = vots;
      chart.update()
    }
    var ctx = document.getElementById('myChart').getContext('2d');
    chart = new Chart(ctx, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
        labels: lab,
        datasets: [{
          label: 'Votos',
          backgroundColor: '#00677F',
          borderColor: '#00677F',
          data: vots,
        }]
      },

      // Configuration options go here
      options: {
        title: {
          display: true,
          text: tit,
          padding: 10,
          lineHeight: 1,
          fontSize: 16
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#EEE',
            },
            ticks: {
              beginAtZero: true,
            }
          }],
          xAxes: [{
            gridLines: {
              color: '#EEE',
            },
          }]
        },
        legend: {
          display: false,
        },
        events: []
      }
    });
  }

  useEffect(() => {

    socket.on("countdown", (data, id, triv, times) => {
      const display = document.getElementById(`counterR${triv._id}`);
      const duration = times;
      if (data === 'start') {
        startTimer(duration, display, id, triv)
      }
    });

    socket.on("chart", (triviaIDToChart, questionIDToChart) => {
      let conf = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      };

      fetch(`${API}/trivia/answer/${triviaIDToChart}/${questionIDToChart}`, conf)
        .then(res => res.json())
        .then(ansData => {
          // Get count for each choice
          const stad = {};
          ansData.forEach((answer) => {
            if (!stad[answer.choiceText]) {
              stad[answer.choiceText] = 1;
            } else {
              stad[answer.choiceText]++;
            }
          });

          // Turn to Array
          const stadArray = [];
          for (const choice in stad) {
            const singleObj = {};
            singleObj[choice] = stad[choice];
            stadArray.push(singleObj);
          }
          //console.log(stadArray);

          stadArray.forEach((data) => {
            labels.push(Object.keys(data))
            votes.push(Object.values(data))
          })

          updatingChart(labels, votes, "Pregunta")
          labels = [];
          votes = [];
        })

      // socket to print chart in user

    })

    socket.on("winnersObj", (score, triviaID, id) => {

      if (createdTrivia) {
        const toDelete = createdTrivia.find(element => element._id === id)
        const newArr = createdTrivia.filter(element => element !== toDelete)
        setCreatedTrivia(newArr)
        if (newArr.length === 0) {
          winnersObj = {}
          //setWinnersObj({})
        }
      }

      if (createdTrivia.length === 1) {

        var conf = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
          },
        };

        fetch(`${API}/trivia/calculate/finalscore/${triviaID}`, conf)
          .then(res => res.json())
          .then(res => {
            console.log(res);
            let results = []

            winnersObj = res

            if (res.length > 5) {
              results = winnersObj.splice(0, 5)
            } else {
              results = winnersObj
            }
            socket.emit('gold', {
              results: results,
              gameSelect: gameSelect,
              triviaID: triviaID,
              questionID: id
            })
          })
      }
    })


    return () => { socket.off() }
  }, [createdTrivia, countTimes, winnersObj]);



  const plusHandler = () => {
    setTrivia([...trivia, { text: '', answerId: `${trivia.length}-0`, choices: [{ text: '', _id: `${trivia.length}-0` }] }])
  }

  const lessHandler = () => {
    if (trivia.length > 1) {
      let triviaClone = [...trivia]
      triviaClone.splice(trivia.length - 1, 1)
      setTrivia(triviaClone)
    }
  }

  const plusCardHandler = (index) => {
    let triviaClone = [...trivia]
    triviaClone[index].choices.push({ text: '', _id: `${index}-${triviaClone[index].choices.length}` })
    setTrivia(triviaClone)
  }

  const lessCardHandler = (index) => {
    if (trivia.length > 0 && trivia[index].choices.length > 1) {
      let triviaClone = [...trivia]
      triviaClone[index].choices.splice(triviaClone[index].choices.length - 1, 1)
      setTrivia(triviaClone)
    }
  }

  const onChangeQuestion = (e, i) => {
    let triviaClone = [...trivia]
    triviaClone[i].text = e.target.value
    setTrivia(triviaClone)
  }

  const onChangeCard = (e, index, i) => {
    let triviaClone = [...trivia]
    triviaClone[index].choices[i].text = e.target.value
    setTrivia(triviaClone)
    //console.log(listOfWinners);
  }

  const createTrivia = () => {
    cancelTrivia()
    let send = true
    for (const item of trivia) {
      if (item.text.trim() === '' || item.choices.length === 0) {
        send = false
        alert("Faltan campos por llenar", false)
        break
      }
      for (const i of item.choices) {
        if (i.text.trim() === '') {
          send = false
          alert("Faltan campos por llenar", false)
          break
        }
      }
    }
    updatingChart(labels, votes, "Pregunta")
    if (send) {
      let conf = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ trivia: trivia }),
      };

      fetch(API + "/trivia/new", conf)
        .then(res => res.json())
        .then((dataTrivia) => {
          console.log("TRIVIA SENT", dataTrivia);
          setCreatedTriviaId(dataTrivia._id)
          setCreatedTrivia(dataTrivia.trivia);
          socket.emit('start-game', trivia.length);
          setTrivia([{ text: '', answerId: '0-0', choices: [{ text: '', _id: '0-0' }] }])
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const onChangeSelection = (e, index) => {
    let triviaClone = [...trivia]
    triviaClone[index].answerId = e.target.value
    setTrivia(triviaClone)
  }

  const sendQuestion = (triviaId, question) => {
    time = true
    //setTime(true)
    socket.emit("trivia-new-question", triviaId, question, parseInt(timeByQuestions));
  }

  const stopQuestion = (triviaId, questionId) => {
    if (time) {
      console.log("manual stop");
      time = false
      //setTime(false)
      socket.emit("trivia-stop", triviaId, questionId);
    }
  }

  const startTimer = (duration, display, id, trivia) => {
    var timer = (duration + 2), minutes, seconds;
    const inter = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;
      if (timer-- === 0) {
        console.log('Time over');
        timer = 0;
        clearInterval(inter)
        console.log(time);
        if (time) {
          console.log('automatic Stop');
          stopQuestion(id, trivia._id);
        }
      }
    }, 1000);
  }

  const cancelTrivia = () => {
    winnersObj = {}
    setCreatedTrivia(false)
    setCountTimes(0)
    setListOfWinners([])
    updatingChart([], [], 'Encuesta')
    socket.emit('cancel-trivia', 'cancel')
  }

  return (
    <div className='adminTriviaContainer'>
      <SlideCheck state={{ slideCheck: [gameSelect, setGameSelevt] }}/>
      <p>Por defecto es ENCUESTA</p>
      {
        gameSelect
        ? <h1>Trivia</h1>
        : <h1>Encuesta</h1>
      }

      {
        gameSelect
        ? <h4>Agregar o quitar preguntas:</h4>
        : null
      }

      {
        gameSelect
        ? <div className='adminTriviaOptions'>
            <button className='adminTriviaPlus' onClick={plusHandler}>+</button>
            <button className='adminTriviaLess' onClick={lessHandler}>-</button>
          </div>
        : null
      }
      <div className='adminTriviaCards'>
        {
          trivia.map((card, index) => (
            <form className='adminTriviaCard' key={index}>
              <textarea placeholder='PREGUNTA' value={card.text} onChange={(e) => onChangeQuestion(e, index)}></textarea>
              <h4>Agregar o quitar respuestas:</h4>
              <div className='adminTriviaCardOptions'>
                <button className='adminTriviaPlus' type='button' onClick={(e) => plusCardHandler(index)}>+</button>
                <button className='adminTriviaLess' type='button' onClick={(e) => lessCardHandler(index)}>-</button>
              </div>
              {
                card.choices.map((item, i) => (
                  <div key={i}>
                    <p>{item._id}</p>
                    <textarea placeholder={`Respuesta ${item._id}`} value={item.text} onChange={(e) => onChangeCard(e, index, i)}></textarea>
                  </div>
                ))
              }
              <h3>Elige la respuesta correcta:</h3>
              <select value={card.answerId} onChange={(e) => onChangeSelection(e, index)}>
                {
                  card.choices.map((item, i) => (
                    <option value={item._id} key={i}>{item.text}</option>
                  ))
                }
              </select>
            </form>
          ))
        }
      </div>
      <input type="number" className="valueOfTime" value={timeByQuestions} onChange={e => {setTimeByQuestions(parseInt(e.target.value || ''))}} placeholder="Tiempo en segundos"/>
      <button className='adminTriviaSend' onClick={createTrivia} >Enviar</button>
      <hr style={{ width: '100%' }} />
      <div className="chartContainer">
        <canvas id="myChart" >
        </canvas>
      </div>
      <div className='adminTriviaCreated'>
        {createdTrivia ?
          createdTrivia.map((triv, i) => (
            <div key={i} className='adminTriviaCreatedCard'>
              <h2>{triv.text}</h2>
              <div className='adminTriviaCardOptions'>
                <button className='adminTriviaCreatedButton1' onClick={() => sendQuestion(createdTriviaId, triv)} disabled={time}>Enviar</button>
                <button className='adminTriviaCreatedButton2' onClick={() => stopQuestion(createdTriviaId, triv._id)}>Detener</button>
              </div>
              <h2 id={`counterR${triv._id}`}>00:00</h2>
            </div>
          )) : ''
        }
      </div>
      {
        createdTrivia ? <button className='adminTriviaCancel' onClick={cancelTrivia}>Cancel {gameSelect ? "Trivia" : "Encuesta"}</button> : ''
      }
      {listOfWinners.length > 0 ?
        <h1>List of winners</h1> : ''
      }
      {(listOfWinners && listOfWinners.length) ? listOfWinners.map((winner, i) => (
        <div className="winner" key={i}>
          <h4>{winner.score} </h4>
          <div style={{ width: '1vh' }}></div>
          <h3> {winner.name}</h3>
        </div>
      )) : ''}
    </div>
  )
}

export default TriviaAdmin
